import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-4 items-center flex-row flex-no-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-lp-300 text-12px absolute font-normal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeRangePicker = _resolveComponent("TimeRangePicker")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      _createVNode(_component_TimeRangePicker, _mergeProps({
        startDate: _ctx.filterClientDashboard.startDate,
        endDate: _ctx.filterClientDashboard.endDate,
        onSelect: _ctx.setDateRange
      }, _ctx.timeRangePickerAttributes), null, 16, ["startDate", "endDate", "onSelect"]),
      (_ctx.isErrorTimeRange)
        ? (_openBlock(), _createBlock("div", _hoisted_2, " Tanggal tidak boleh lebih dari 365 hari "))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_CustomDropdown, {
      class: "w-56",
      options: _ctx.optionProduct,
      onSelect: _ctx.selectProduct,
      placeholder: "Pilih layanan",
      isCustomWidth: "",
      selectedItem: _ctx.filterClientDashboard.product
    }, null, 8, ["options", "onSelect", "selectedItem"]),
    _createVNode(_component_CustomDropdown, {
      class: "w-56",
      options: _ctx.optionDeliveryType,
      onSelect: _ctx.selectDeliveryType,
      placeholder: "Pilih pengiriman",
      isCustomWidth: "",
      selectedItem: _ctx.filterClientDashboard.deliveryType
    }, null, 8, ["options", "onSelect", "selectedItem"]),
    (_ctx.isClientParent)
      ? (_openBlock(), _createBlock(_component_CustomDropdown, {
          key: 0,
          class: "w-56",
          options: _ctx.mappedDataClient,
          onSelect: _ctx.selectBranch,
          placeholder: "Pilih pengiriman",
          isCustomWidth: "",
          selectedItem: _ctx.filterClientDashboard.branch
        }, null, 8, ["options", "onSelect", "selectedItem"]))
      : _createCommentVNode("", true)
  ]))
}