
import { Vue, Options } from "vue-class-component";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ClientController } from "@/app/ui/controllers/ClientController";
import {
  ClientMappingList,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ProductSender } from "@/domain/entities/Product";

@Options({
  emits: ["onFilter"]
})
export default class SectionFilterDashboard extends Vue {
  created() {
    DashboardController.resetFilterCorporateDashboard();
    if (!IS_SENDER_ACCOUNT) {
      this.fetchProducts();
    } else {
      this.getProductSenderList();
    }
    if (this.isClientParent) {
      this.fetchClientBranch("");
    }
  }
  get dataProfile() {
    return AccountController.accountData;
  }
  get isClient() {
    return this.dataProfile.account_type === "client";
  }
  get isClientParent() {
    if (IS_SENDER_ACCOUNT) {
      return false;
    }
    return this.isClient && !this.dataProfile.account_type_detail.parentId;
  }
  get filterClientDashboard() {
    return DashboardController.filterCorporateDashboard;
  }
  // filter date
  isErrorTimeRange = false;
  get timeRangePickerAttributes() {
    return {
      maxDate: new Date(),
      default: "7 hari terakhir",
      showOptions:
        "custom, hari ini, kemarin, 7 hari terakhir, bulan ini, tahun ini",
      isCloseDateAfterSelect: true,
      isConvertName: true,
      advanceStyle: {
        width: "600px",
        fontSize: "text-lg"
      }
    };
  }
  setDateRange(value: Array<any>) {
    this.filterClientDashboard.startDate = value[0];
    this.filterClientDashboard.endDate = value[1];
    const differenceDate = this.$moment(value[1]).diff(
      this.$moment(value[0]),
      "days"
    );
    if (differenceDate < 365) {
      this.isErrorTimeRange = false;
      this.$emit("onFilter");
      return;
    }
    this.isErrorTimeRange = true;
  }

  // filter products
  selectProduct(item: any) {
    if (this.filterClientDashboard.product.value === item.value) return;
    this.filterClientDashboard.product = item;
    this.$emit("onFilter");
  }
  fetchProducts() {
    BookingController.getProductsDelivery({
      search: "",
      page: 1,
      limit: 10,
      status: "active"
    });
  }
  get optionProduct() {
    if (IS_SENDER_ACCOUNT) {
      return [
        new OptionsClass({
          name: "Semua Layanan",
          value: "all"
        }),
        ...this.productOptions
      ];
    }
    return [
      {
        name: "Semua Layanan",
        value: "all"
      },
      ...BookingController.productsDelivery.data.map((e: any) => ({
        name: e.name,
        value: e.name
      }))
    ];
  }
  productOptions: OptionsClass[] = [];
  async getProductSenderList(): Promise<void> {
    const res: ResponsePayloadV2 = await ProductController.getProductSenderList(
      new ApiRequestList({
        page: 1,
        limit: 100,
        status: "active"
      })
    );
    if (res.data.length) {
      this.productOptions = res.data.map(
        (item: ProductSender) =>
          new OptionsClass({
            name: item.productTypeCode,
            value: item.productTypeCode
          })
      );
      ProductController.setProductSenderList(this.productOptions);
    }
  }

  // filter delivery type
  optionDeliveryType = [
    {
      name: "Semua Pengiriman",
      value: "all"
    },
    { name: "COD", value: "cod" },
    { name: "Non COD", value: "non_cod" }
  ];
  selectDeliveryType(item: any) {
    if (this.filterClientDashboard.deliveryType.value === item.value) return;
    this.filterClientDashboard.deliveryType = item;
    this.$emit("onFilter");
  }

  // filter client branch
  fetchClientBranch(value: string) {
    ClientController._onGetCLientMappingList(
      new RequestApiClientMappingList({
        page: 1,
        limit: 100,
        search: value,
        isBranch: true
      })
    );
  }
  get mappedDataClient() {
    return [
      { id: 0, name: "Semua Cabang", value: "all" },
      ...ClientController.clientMappingData.map(
        (client: ClientMappingList) => ({
          id: client.clientId,
          name: client.clientName,
          value: client.clientId
        })
      )
    ];
  }
  selectBranch(item: any) {
    if (this.filterClientDashboard.branch.value === item.value) return;
    this.filterClientDashboard.branch = item;
    this.$emit("onFilter");
  }
}
